import globalStore from '@/services/global.state';
import {getAdjustAttribution} from '@/utils/adjust';
import {http, getLoginHttp, getAttribution} from '@utils';
import DeviceInfo from 'react-native-device-info';

export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', {userPhone});
};

export const userLogin = async (
  userPhone: string,
  code: string,
  userInviteCode?: string,
  isLogin: boolean = true,
) => {
  const date: {[k: string]: any} = {
    userPhone,
    code,
    userInviteCode,
    ...getAttribution(userInviteCode),
  };
  if (!isLogin && globalStore.isWeb && !userInviteCode) {
    date.userInviteCode = localStorage.getItem('invitationCode') || undefined;
  }

  if (globalStore.isWeb) {
    const attr = getAdjustAttribution();
    if (attr && attr.adid) {
      date.isReport = attr?.tracker_name !== 'Organic';
      date.adid = attr?.adid || '';
    }
  }

  const deviceCode = globalStore.isWeb
    ? getAdjustAttribution()?.adid
    : await DeviceInfo.getAndroidId();

  return getLoginHttp({
    deviceCode,
  }).post<
    null,
    {
      token: string;
      isNewUser: boolean;
    }
  >('app/userLoginNew', date);
};

export const passwordLogin = (userPhone: string, password: string) => {
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
  });
};

export const updatePassword = (password: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
  });
};
